import React from "react"
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero1 from "../components/Hero/Hero1"
import Recognition from "../components/Recognition"
import HyperAutomation from "../components/HyperAutomation"
import Container from "../components/ui/Container"
import Card1 from "../components/Card/Card1"
import { StaticImage } from "gatsby-plugin-image"
import Cta1 from "../components/Cta/Cta1"
import { useState } from "react";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";


const IndexPage = () => {
  const [showFirst, setShowFirst] = useState(true);
    const [showSecond, setShowSecond] = useState(false);
    const [showthird, setShowthird] = useState(false);
    return (
  <Layout>
    <SEO title="Home" />
    

 
  
  <Container>
  
      
    <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-2  lg:gap-14  p-[1rem]  lg:place-items-center mb-[7rem]">
    <div>
    <div className="grid lg:grid-cols-2  grid-cols-2" >
        <div>  <span className="hm1"> Unlimit</span></div>
        <div className="scroller">
            
            <span className="hm2">
            Productivity<br/>
            Efficiency<br/>
            Capacity<br/>
            Scalability
            </span>
          </div>
        </div>
         
            <span className="hm3 ">with Hyperautomation.</span>

            
            <p className="hm5 mt-[1rem]">
            Experience the power of seamlessly automating even complex processes with RAP’s scalable & flexible hyperautomation platform.

            </p>

<div className="grid lg:grid-cols-2   mt-[1rem] dnone">
<div>
<button className="hm7 rounded">
              Read More
            </button>
</div>

<div className="ml-[-5rem]">

<button className="hm6 rounded ">
            Talk to an Expert
            </button>
</div>
</div>
            
            <button className="hm7 rounded dnonedesk">
              Read More
            </button>
            
            &emsp;
            <button className="hm6 rounded dnonedesk">
            Talk to an Expert
            </button>
          </div>
          <div className=" ">
            <img
              className=" "
              src="/images/Healthcare.png"
              alt="Sunset in the mountains"
            />
          </div>
         
        
        </div>

        
    
         
      

        </Container>

        

<Container>
        <div className="grid lg:grid-cols-4 grid-cols-2  gap-4 place-items-center  lg:divide-x-[2px] lg:shadow-lg mt-[-9rem] p-[1rem]">
         <div>
       <img src="images/aicpa.png"/>
        
         
         </div>
             
         

         <div>
         <div className="ctext count-up"><span  className="hm8  count"></span><span className="hm9"> M+</span></div>
         <p className="pctext ml-[2rem]">Content pieces Processed</p>
         </div>
         <div>
         <div className="ctext"><span className="hm8">25</span><span className="hm9"> M+</span></div>
         <p className="pctext ml-[2rem]">Processed Iterations</p>
         </div>
         <div>
         <div className="ctext "><span className="hm8">100%</span></div>
         <p className="pctext ml-[2rem]">of Combined Experience</p>
         </div>
         

          
            
          
         
        
        </div>  
        </Container>
<Container>
    <div className="mt-[6rem] flex items-center justify-center">
<img src="images/home-bg.png"/>
</div>
</Container>
<Container>



           
<h1 className="hm10 mt-[3rem]">Ultra-flexible, ultra-scalable platform that knows no limits.
</h1>
<div className="grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-2   hm47  lg:place-items-center ">
<div className="mwid">
{
            showFirst && (
                
                  
  <img src="/images/bot1.png"/>
                
            )
        }
        {
            showSecond && (
                
                      <img src="/images/bot2.png"/>
              
            )
        }
           {
            showthird && (
                
                    <img src="/images/bot3.png"/>
                
            )
        }


  
</div>


<div className="col-span-2 hm48">
<div className="grid grid-cols-3     p-[1rem] lg:place-items-center ">
<div  onClick={() => {
                      setShowFirst(!showFirst);
                      setShowSecond(false);
                      setShowthird(false);
                    }} className="hm45"
                    >Overview</div>
<div onClick={() => {
                      setShowSecond(!showSecond);
                      setShowFirst(false);
                      setShowthird(false);
                    }} className="hm45"> RAPFlow™</div>
<div onClick={() => {
                      setShowSecond(false);
                      setShowFirst(false);
                      setShowthird(!showthird);
                    }} className="hm45">RAPBot™</div>

</div>
<hr className="new1"></hr>
{
            showFirst && (
                <div>
                    <p className="hm44 hm49">
                    Introducing the RAP™ Platform, the most complete Intelligent Process Automation platform on the market. Our twin platform RAPFlow™ and RAPBot™, is loaded with powerful Artificial Intelligence (AI), ML, DL, Computer Vision, Natural Language Processing, and Robotic Process Automation capabilities. The unified platform automates your processes end-to-end at record speed, even the most complex ones.
                    <div className="   lg-[2rem]">
          <button className="bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white blogbtn2 border border-blue-500 hover:border-transparent rounded">Explore More</button>
        
                </div>
                    </p>
                 
                    
                </div>
            )
        }
        {
            showSecond && (
                <div>
                    <p className="hm44 hm49">RAPFlow™ is a full-lifecycle AI (Artificial Intelligence) orchestration platform that enables you to create AI pipelines as simple as playing legos. Orchestrate an AI solution for processing unstructured content in no time. It’s low code, low cost, low data and no specialized AI knowledge is required. Even better, it augments your existing systems through open integrations.
                    <div className="   lg-[2rem]">
          <button className="bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white blogbtn2 border border-blue-500 hover:border-transparent rounded">Explore More</button>
        
                </div>
</p>

                </div>
            )
        }
           {
            showthird && (
                <div>
                    <p className="hm44 hm49">RAPBot™ is an intelligent RPA (Robotic Process Automation) platform built to easily automate high-volume, rule-based, and data-intensive processes. The platform makes automation a piece of cake and engaging, with app-like interfaces that anyone can use. The flexible low-code features make it a treat for serious RPA developers to test their boundaries on solving problems through automation.


                    <div className="   lg-[2rem]">
          <button className="bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white blogbtn2 border border-blue-500 hover:border-transparent rounded">Explore More</button>
        
                </div>
</p>

                </div>
            )
        }
</div>
  </div>
</Container>

<Container>
<div className=" p-[1rem] ">
    <h1 className="hm10 lg:mt-[5rem] mt-[10rem] mtmob1">Most complete IPA offering yet</h1>

<div className="grid lg:grid-cols-3 md:grid-cols-2   gap-4  mt-[3rem] wid-[100rem]">
<div>
<p className="hm39">Build pipelines more flexible than your yoga instructor</p>
</div>
<div>
<p className="hm39">
Get your hands on Low-Code/No-Code platform 
</p>
    </div>
    <div>
    <p className="hm39">Fits like a glove with your existing systems</p>
    </div>
    <div>
    <p className="hm39">Automate processes in any operating system</p>
    </div>
    <div>
    <p className="hm39">Deploy anywhere: On-prem, private or public cloud</p>
        </div>
        <div>
        <p className="hm39">Deploy solutions in record time</p>
        </div>
        <div>
        <p className="hm39">Lowest infrastructure footprint</p>
</div>
<div>
<p className="hm39">Pay as you go - No lock-in periods!</p>
</div>
<div>
<p className="hm39">Build Scalable Solutions</p>
    </div>
   
</div>
</div>
</Container>
<div className="hm19  p-[1rem] lg:mt-[7rem]">
    <h1 className="hm10 mt-[3rem]">Think beyond task automation - It's really a world apart!</h1><br/>
<p className="hm11 ">You are really missing the icing on the cake without hyperautomation</p>
<div className="grid lg:grid-cols-3 md:grid-cols-2   gap-4 place-items-center">
 

 <div>
  <h1 className="hm16 hm34">Manual Processes</h1>
  <p className="hm18"><i className="fa fa-check-circle hm20"></i> Slow and inefficient process</p>
  <p className="hm18"><i className="fa fa-check-circle hm20"></i> Inability to scale on demand</p>
  <p className="hm18"><i className="fa fa-check-circle hm20"></i> Very high operating costs</p>
  <p className="hm18"><i className="fa fa-check-circle hm20"></i> Error-prone / data inconsistencies</p>
  <p className="hm18"><i className="fa fa-check-circle hm20"></i> Time spent on mundane tasks</p>
  
 </div>
 <div>
  <img src="/images/donut.png"/>
 </div>
 <div>
 <h1 className="hm16 hm33">Intelligent Automation</h1>
  <p className="hm17">Faster process with higher efficiency <i className="fa fa-check-circle hm20"></i></p>
  <p className="hm17 ">Highly scalable process <i className="fa fa-check-circle hm20"></i></p>
  <p className="hm17">Reduction in opex by 30-70% <i className="fa fa-check-circle hm20"></i></p>
  <p className="hm17">Guaranteed predictable outcomes <i className="fa fa-check-circle hm20"></i></p>
  <p className="hm17">Time available for value added tasks <i className="fa fa-check-circle hm20"></i></p>
  
  </div>
 



</div>
  

</div>


        <Container>
    <h1 className="hm10 mt-[7rem]">Committed to making Hyperautomation work for you</h1><br/>
<p className="hm11 ">Enabling companies save hours of drudgery everyday and build resilient operations</p>
<div className="grid lg:grid-cols-5 grid-cols-2 md:grid-cols-3 gap-4  bgp">
<div className="flex justify-center">
  <div className="block p-6  bg-white max-w-sm">
    <img className="img1" src="/images/p1.png"/>

    
  
  </div>
</div>
<div className="flex justify-center">
  <div className="block p-6  bg-white max-w-sm">
    <img className="img1" src="/images/p2.png"/>

   

  </div>
</div>
<div className="flex justify-center">
  <div className="block p-6  bg-white max-w-sm">
    <img className="img1" src="/images/p3.png"/>

   

  </div>
</div>
<div className="flex justify-center">
  <div className="block p-6 bg-white max-w-sm">
    <img className="img1" src="/images/p4.png"/>

   

  </div>
</div>
<div className="flex justify-center">
  <div className="block p-6  bg-white max-w-sm">
    <img className="img1" src="/images/p5.png"/>

 

  </div>
</div>
<div className="flex justify-center">
  <div className="block p-6  bg-white max-w-sm">
    <img className="img1" src="/images/p6.png"/>

 
  
  </div>
</div>
<div className="flex justify-center">
  <div className="block p-6 bg-white max-w-sm">
    <img className="img1" src="/images/p7.png"/>


  
  </div>
</div>
<div className="flex justify-center">
  <div className="block p-6 bg-white max-w-sm">
    <img className="img1" src="/images/p8.png"/>

  
  </div>
</div>
<div className="flex justify-center">
  <div className="block p-6  bg-white max-w-sm">
    <img className="img1" src="/images/p9.png"/>

 
  
  </div>
</div>
<div className="flex justify-center">
  <div className="block p-6 bg-white max-w-sm">
    <img className="img1" src="/images/p10.png"/>

  
  </div>
</div>
</div>

</Container>
<Container>
    {/* <div className="mt-[7rem]">
<Swiper navigation={true} modules={[Navigation]} className="mySwiper">
  
       
  <SwiperSlide>
  <div className="flex flex-col  ">
<div
  className=" flex rounded-xl shadow-lg p-3 max-w-xs md:max-w-3xl  border border-white bg-white">
  <div className="w-full md:w-1/3 bg-white grid ">
      <img src="https://images.pexels.com/photos/4381392/pexels-photo-4381392.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="tailwind logo" className="rounded-xl" />
</div>
      <div className="w-full md:w-2/3 p-[1rem]">
          
      
<p className="">The best kept secret of The Bahamas is the country’s sheersize and diversity. With 16 major islands, The Bahamas is an unmatched destination</p>
              <h3 className="hm26">The Majestic </h3>
              <h3 className="hm27"> Bahamas</h3>
      </div>
  </div>
</div>

  </SwiperSlide>
  <SwiperSlide>
  <div className="flex flex-col  ">
<div
  className=" flex rounded-xl shadow-lg p-3 max-w-xs md:max-w-3xl  border border-white bg-white">
  <div className="w-full md:w-1/3 bg-white grid ">
      <img src="https://images.pexels.com/photos/4381392/pexels-photo-4381392.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="tailwind logo" className="rounded-xl" />
</div>
      <div className="w-full md:w-2/3 p-[1rem]">
          
      
<p className="">The best kept secret of The Bahamas is the country’s sheersize and diversity. With 16 major islands, The Bahamas is an unmatched destination</p>
              <h3 className="hm26">The Majestic and Wonderful Bahamas</h3>
              <h3 className="hm27">The Majestic and Wonderful Bahamas</h3>
      </div>
  </div>
</div>

  </SwiperSlide>
  <SwiperSlide>
  <div className="flex flex-col  ">
<div
  className=" flex rounded-xl shadow-lg p-3 max-w-xs md:max-w-3xl  border border-white bg-white">
  <div className="w-full md:w-1/3 bg-white grid ">
      <img src="https://images.pexels.com/photos/4381392/pexels-photo-4381392.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="tailwind logo" className="rounded-xl" />
</div>
      <div className="w-full md:w-2/3  p-[1rem]">
          
      
<p className="">The best kept secret of The Bahamas is the country’s sheersize and diversity. With 16 major islands, The Bahamas is an unmatched destination</p>
              <h3 className="hm26">The Majestic and Wonderful Bahamas</h3>
              <h3 className="hm27">The Majestic and Wonderful Bahamas</h3>
      </div>
  </div>
</div>

  </SwiperSlide>
</Swiper>
</div> */}
<h1 className="hm29 mt-[7rem]">Breaking productivity barriers one usecase at a time</h1><br/>

<div className="grid lg:grid-cols-3 md:grid-cols-2 webv1  gap-4 place-items-center pad1rr">
  

  <div>  <a href="#">
        <img className="rounded-t-lg hm23" src="/images/tech.png" alt=""/>
    </a>
<div className="hm24">
  
    <div className="p-5">
        <a href="#">
            <h5 className="hm30">First State Bank
Mortgage</h5>
        </a>
        <p className="hm31">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
        <p className="hm32">
        Read the Story &nbsp; <i className="fa fa-long-arrow-right"></i>
           </p>
    </div>
</div>
</div>
<div>  <a href="#">
        <img className="rounded-t-lg hm23" src="/images/tech.png" alt=""/>
    </a>
<div className="hm24">
  
    <div className="p-5">
        <a href="#">
            <h5 className="hm30">First State Bank
Mortgage</h5>
        </a>
        <p className="hm31">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
        <p className="hm32">
        Read the Story &nbsp; <i className="fa fa-long-arrow-right"></i>
           </p>
    </div>
</div>
</div>
<div>  <a href="#">
        <img className="rounded-t-lg hm23" src="/images/tech.png" alt=""/>
    </a>
<div className="hm24">
  
    <div className="p-5">
        <a href="#">
            <h5 className="hm30">First State Bank
Mortgage</h5>
        </a>
        <p className="hm31">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
        <p className="hm32">
        Read the Story &nbsp; <i className="fa fa-long-arrow-right"></i>
           </p>
    </div>
</div>
</div>

</div>
<div className="flex items-center justify-center mt-[2rem]">
          <button className="bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white blogbtn2 border border-blue-500 hover:border-transparent rounded">Explore More</button>
        
                </div>
</Container>
{/* <Container>
    <h1 className="hm10 lg:mt-[10rem]">Ultra-flexible, ultra-scalable platform built to evolve with you.
</h1>

<div className="grid lg:grid-cols-2 md:grid-cols-1 webv1  gap-4 place-items-center pad1rr mt-[2rem]">
 
<div className=" hm14 ">
  
   <div className="flow-root">
        <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
            <li className=" ">
                <div className="flex items-center space-x-4 ml-[1rem]">
                    <div className="flex-shrink-0">
                        <img className="w-16 h-16 rounded-full" src="/images/webinar-3.png" alt="Neil image"/>
                    </div>
                    <div className="flex-1 ">
                      
                    <p className="hm15">
                        Create frictionless process pipelines with no manual and repetitive tasks. Supercharge productivity and think process at scale.</p>
                       
                    </div>
                   
                </div>
            </li>
          
          
          
          
        </ul>
   </div>
</div>
 
<div className=" hm14 ">
  
   <div className="flow-root">
        <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
            <li className=" ">
                <div className="flex items-center space-x-4 ml-[1rem]">
                    <div className="flex-shrink-0">
                        <img className="w-16 h-16 rounded-full" src="/images/webinar-3.png" alt="Neil image"/>
                    </div>
                    <div className="flex-1 ">
                      
                    <p className="hm15">
                        Create frictionless process pipelines with no manual and repetitive tasks. Supercharge productivity and think process at scale.</p>
                       
                    </div>
                   
                </div>
            </li>
          
          
          
          
        </ul>
   </div>
</div>
 
<div className=" hm14 ">
  
   <div className="flow-root">
        <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
            <li className=" ">
                <div className="flex items-center space-x-4 ml-[1rem]">
                    <div className="flex-shrink-0">
                        <img className="w-16 h-16 rounded-full" src="/images/webinar-3.png" alt="Neil image"/>
                    </div>
                    <div className="flex-1 ">
                      
                    <p className="hm15">
                        Create frictionless process pipelines with no manual and repetitive tasks. Supercharge productivity and think process at scale.</p>
                       
                    </div>
                   
                </div>
            </li>
          
          
          
          
        </ul>
   </div>
</div>
 
<div className=" hm14 ">
  
   <div className="flow-root">
        <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
            <li className=" ">
                <div className="flex items-center space-x-4 ml-[1rem]">
                    <div className="flex-shrink-0">
                        <img className="w-16 h-16 rounded-full" src="/images/webinar-3.png" alt="Neil image"/>
                    </div>
                    <div className="flex-1 ">
                      
                    <p className="hm15">
                        Create frictionless process pipelines with no manual and repetitive tasks. Supercharge productivity and think process at scale.</p>
                       
                    </div>
                   
                </div>
            </li>
          
          
          
          
        </ul>
   </div>
</div>

</div>
  
<div className="flex items-center justify-center mt-[3rem]">
          <button className="bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white blogbtn2 border border-blue-500 hover:border-transparent rounded">Explore More</button>
        
                </div>



  

</Container> */}







  
<div className="hm19 p-[1rem] mt-[3rem] mb-[7rem]">
<h1 className="hm10 mt-[3rem] mb-3rem">Transform every corner of your organization
</h1>
<p className="hm11 ">Deliver seamless customer journeys that drive growth, increase productivity, and deepen customer relationships.
</p>
<div className="grid lg:grid-cols-3 md:grid-cols-2 webv1 hm3 lg:mt-[3rem]  gap-4 place-items-center pad1rr">
  

<div className="hm35 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
    <a href="#">
        <img className="rounded-t-lg" src="/images/tech.png" alt=""/>
    </a>
    <div className="p-5">
        <a href="#">
            <h5 className="hm36">Business Unit</h5>
        </a>
        <p className="hm32">
        View Benefits &nbsp; <i className="fa fa-long-arrow-right"></i>
           </p>
    </div>
</div>
<div className="hm35 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
    <a href="#">
        <img className="rounded-t-lg" src="/images/tech.png" alt=""/>
    </a>
    <div className="p-5">
        <a href="#">
            <h5 className="hm36">Business Unit</h5>
        </a>
        <p className="hm32">
        View Benefits &nbsp; <i className="fa fa-long-arrow-right"></i>
           </p>
    </div>
</div>
<div className="hm35 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
    <a href="#">
        <img className="rounded-t-lg" src="/images/tech.png" alt=""/>
    </a>
    <div className="p-5">
        <a href="#">
            <h5 className="hm36">Business Unit</h5>
        </a>
        <p className="hm32">
        View Benefits &nbsp; <i className="fa fa-long-arrow-right"></i>
           </p>
    </div>
</div>
<div className="hm35 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
    <a href="#">
        <img className="rounded-t-lg" src="/images/tech.png" alt=""/>
    </a>
    <div className="p-5">
        <a href="#">
            <h5 className="hm36">Business Unit</h5>
        </a>
        <p className="hm32">
        View Benefits &nbsp; <i className="fa fa-long-arrow-right"></i>
           </p>
    </div>
</div>
<div className="hm35 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
    <a href="#">
        <img className="rounded-t-lg" src="/images/tech.png" alt=""/>
    </a>
    <div className="p-5">
        <a href="#">
            <h5 className="hm36">Business Unit</h5>
        </a>
        <p className="hm32">
        View Benefits &nbsp; <i className="fa fa-long-arrow-right"></i>
           </p>
    </div>
</div>
<div className="hm35 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
    <a href="#">
        <img className="rounded-t-lg" src="/images/tech.png" alt=""/>
    </a>
    <div className="p-5">
        <a href="#">
            <h5 className="hm36">Business Unit</h5>
        </a>
        <p className="hm32">
        View Benefits &nbsp; <i className="fa fa-long-arrow-right"></i>
           </p>
    </div>
</div>



</div>

</div>
<div className="grid lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-4 bg-black lg:p-[2rem] p2r  hm37">
<div className="partnerw">
            <h1 className="partl text-white psm">
            Ready. Set.  <span className="parnerred psm">Hyperautomate.</span>
            </h1>
         
        
          
          </div>

          
<div className="lg:ml-[26rem] md:ml-[5rem] mlf">
<button className="text-align: right; prtbut bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white  border border-blue-500 hover:border-transparent rounded">
            Book a Demo
            </button>
</div>
    </div>

  </Layout>
    )
}

export default IndexPage